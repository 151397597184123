<script setup>
import AshLogo from '@/components/icons/AshLogo.vue'
import ClientList from '../ClientList.vue'
import { userStore } from '@/stores/user'
import { partnersStore } from '@/stores/partners'
import classnames from 'classnames'
import logger from '@/utils/logger'
import {
  checkUserToken,
  clearUserAuth,
  setInactivityLogoutTime,
  getPartnerList
} from '@/utils/handleSession'
import { showSuccessToast } from '@/utils/toastMessages'
</script>

<script>
export default {
  name: 'NavbarTop',
  components: {
    AshLogo,
    ClientList
  },
  props: {
    showSidebar: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      isAuthenticated: false,
      optionSelected: null,
      clientOptions: [],
      isMobile: false,
      loading: false
    }
  },
  async created() {
    this.loading = true
    const store = userStore()
    const partnerStore = partnersStore()

    store.$subscribe(async (mutation, state) => {
      if (!state.user.accessToken) {
        this.isAuthenticated = false
      } else {
        if (state?.user?.selectedClientId === 'demopartner') {
          this.optionSelected = { partnerId: 'demopartner', partnerName: 'Ash Wellness' }
        } else {
          this.optionSelected = this.clientOptions.find(
            (option) => option.partnerId === state.user.selectedClientId
          )
        }
        this.isAuthenticated = true
      }
    })

    this.setPartnerOptions()

    partnerStore.$subscribe(async (mutation, state) => {
      this.setPartnerOptions()
    })
  },
  mounted() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.addEventListeners()
  },
  methods: {
    async updateUserPartnerList() {
      try {
        const { user } = userStore()
        const { selectedClientId, accessToken } = user
        const partnerList = await getPartnerList(accessToken)

        if (partnerList?.length > 0) {
          const { setPartnerList } = partnersStore()
          setPartnerList(partnerList)
          const defaultPartner = partnerList.find(
            (partner) => partner?.partner_id === selectedClientId
          )
          if (defaultPartner?.partner_id && defaultPartner?.partner_name) {
            this.selectOption({
              partnerId: defaultPartner.partner_id,
              partnerName: defaultPartner.partner_name
            })
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async setPartnerOptions() {
      const store = userStore()
      const partnerStore = partnersStore()

      const { partners } = partnerStore.partners
      if (partners?.length) {
        this.clientOptions = partners.map((partner) => {
          return {
            partnerId: partner.partner_id,
            partnerName: partner.partner_name
          }
        })
      }

      if (store.user.accessToken) {
        this.isAuthenticated = true
        this.optionSelected = this.clientOptions.find(
          (option) => option.partnerId === store.user.selectedClientId
        )
      }
    },
    checkDevice() {
      this.isMobile = window.matchMedia('(max-width: 640px)').matches
    },
    selectOption(option) {
      this.optionSelected = option
      const { user, setUser } = userStore()
      const { selectedClientId } = user

      const targetProgram = option.partnerId
      const userScopes = user?.userInfo?.user_info?.scopes
      let isUserAllowedToSwitchProgram = false

      if (userScopes) {
        isUserAllowedToSwitchProgram =
          Object.keys(userScopes).includes(targetProgram) ||
          Object.keys(userScopes).includes('demopartner')
      }

      if (!isUserAllowedToSwitchProgram) {
        const swithAccount = {
          action: 'switchAccount',
          clientId: option.partnerId
        }
        localStorage.setItem('switchAccount', JSON.stringify(swithAccount))
        this.$router.push('/')
      } else {
        if (selectedClientId === option.partnerId) {
          return
        }

        setUser({
          ...user,
          minExpDate: setInactivityLogoutTime(),
          selectedClientId: option.partnerId
        })
        showSuccessToast('Account Switch Success', 'Success:')
      }
    },
    goToPage(page) {
      this.$router.push(page)
    },
    getTopBarClassNames() {
      return classnames('tw-bg-dark-blue tw-flex tw-gap-4 tw-h-20', {
        'tw-h-20': !this.isMobile,
        'tw-h-32 tw-py-2 !tw-flex-col': this.isMobile && this.isAuthenticated
      })
    },
    getLogoClassNames() {
      return classnames('tw-flex tw-justify-center tw-items-center', {
        'tw-ml-4': !this.isAuthenticated
      })
    },
    handleShowSidebar() {
      this.$emit('showSidebar')
    },
    async resetCookie() {
      // Check if user token is still valid
      const hasUserToken = checkUserToken()
      if (!hasUserToken) {
        //remove event listeners
        this.removeEventListeners()

        // Clear user data
        const { clearUser } = userStore()
        clearUser()
        await clearUserAuth()

        // Redirect to login
        this.$router.push('/')
      }
    },
    gotToHome() {
      localStorage.removeItem('encodedToken')
      this.$router.push('/dashboard')
    },
    async logout() {
      const { clearUser } = userStore()

      try {
        clearUser()
        this.removeEventListeners()
        const isLoggedOut = await clearUserAuth()

        if (isLoggedOut) {
          this.$router.push('/')
        }
      } catch (error) {
        logger.error('An error occurred while performing this request!', error)
      }
    },
    addEventListeners() {
      this.removeEventListeners()
      window.addEventListener('mousemove', this.resetCookie)
      window.addEventListener('keydown', this.resetCookie)
      window.addEventListener('scroll', this.resetCookie)
    },
    removeEventListeners() {
      window.removeEventListener('mousemove', this.resetCookie)
      window.removeEventListener('keydown', this.resetCookie)
      window.removeEventListener('scroll', this.resetCookie)
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkDevice)
  },
  watch: {
    isAuthenticated() {
      const { user } = userStore()
      if (user?.accessToken) {
        this.updateUserPartnerList()
      }
    },
    $route() {
      const { user } = userStore()
      const { partners } = partnersStore()

      if (user?.accessToken) {
        this.isAuthenticated = true
        this.addEventListeners()
        if (partners.partners.length === 0) {
          this.loading = true
          this.updateUserPartnerList()
        } else {
          this.loading = false
        }
      } else {
        this.isAuthenticated = false
        this.removeEventListeners()
      }
    }
  }
}
</script>

<template>
  <div id="navbar" :class="getTopBarClassNames()">
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-6 tw-w-full">
      <div v-if="isAuthenticated" class="tw-w-[200px] tw-cursor-pointer" @click="handleShowSidebar">
        <div
          class="tw-inline-flex tw-w-full tw-font-light tw-font-noto tw-relative tw-items-center tw-justify-between tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-text-gray-900 tw-shadow-sm"
        >
          <img id="menuIcon" class="navbar-toggle" src="@/assets/menu-item.svg" />
        </div>
      </div>
      <div :class="getLogoClassNames()">
        <AshLogo id="ashLogo" @click="gotToHome" class="tw-cursor-pointer" data-testid="ash-logo" />
      </div>
      <ClientList
        data-testid="client-list"
        v-if="!isMobile && isAuthenticated"
        :clientOptions="clientOptions"
        @optionSelected="selectOption"
        :selected="optionSelected"
        :is-mobile="isMobile"
        :loading="loading"
      />
      <div class="tw-flex tw-justify-end tw-items-center tw-mr-4 tw-w-full tw-gap-4">
        <!-- <RouterLink to="/help" class="tw-text-white tw-text-[14px]">Help</RouterLink> -->
        <div
          @click="logout"
          v-if="isAuthenticated"
          class="tw-text-white tw-text-[14px] tw-font-aeonik hover:tw-text-white hover:tw-underline hover:tw-underline-offset-4 tw-cursor-pointer"
        >
          Log Out
        </div>
      </div>
    </div>

    <div v-if="isMobile && isAuthenticated" class="tw-w-full tw-px-3">
      <ClientList
        :clientOptions="clientOptions"
        @optionSelected="selectOption"
        :selected="optionSelected"
        :isMobile="isMobile"
        :loading="loading"
      />
    </div>
  </div>
</template>
