import axios from 'axios'
import { initializeVariables } from '../services/integrations/integrations.js'

const { baseURL } = initializeVariables()
export const axiosClient = axios.create({ baseURL })

axiosClient.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.hostname
axiosClient.defaults.withCredentials = true
axiosClient.defaults.timeout = 8000

axiosClient.interceptors.request.use((request) => {
  const token = sessionStorage.getItem('token')

  if (token) {
    request.headers['x-access-token'] = token
  }

  request.headers['Content-Type'] = 'application/json'
  request.headers['accept'] = 'application/json'

  return request
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data?.message === 'You are not permitted to perform this action.'
    ) {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user_info')

      window.location.href = '/'

      return
    }

    const customError = new Error()
    const defaultMessage = 'An unexpected error occurred'

    customError.message = error.response?.data?.detail || defaultMessage

    if (error.response?.status === 422) {
      console.log(error.response.data.detail)
      customError.message = !Array.isArray(error.response.data.detail)
        ? error.response.data.detail
        : error.response.data.detail[0].msg
    }

    customError.toString = function () {
      return this.message
    }

    return Promise.reject(customError.toString())
  }
)
