function getBaseURL(url) {
  const urlArray = url.split('.')

  if (
    window.location.hostname.match('client.dev.ashwellness.io') ||
    window.location.hostname === 'localhost'
  ) {
    return [urlArray[0], 'dev', ...urlArray.slice(1)].join('.')
  } else if (window.location.hostname.match('client.staging.ashwellness.io')) {
    return [urlArray[0], 'staging', ...urlArray.slice(1)].join('.')
  } else {
    return url
  }
}

export const config = {
  api: {
    baseURL: getBaseURL(import.meta.env.VITE_API_BASE_URL)
  },
  sentry: {
    local: {
      dsn: import.meta.env.VITE_SENTRY_DSN_DEV
    },
    development: {
      dsn: import.meta.env.VITE_SENTRY_DSN_DEV
    },
    staging: {
      dsn: import.meta.env.VITE_SENTRY_DSN_STAGING
    },
    production: {
      dsn: import.meta.env.VITE_SENTRY_DSN_PRODUCTION
    }
  }
}
