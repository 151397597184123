import { config } from '../../data/config'

export const initializeVariables = () => {
  let sentryDsn = config.sentry.production.dsn
  let baseURL = config.api.baseURL

  if (window.location.hostname.match('client.staging.ashwellness.io')) {
    sentryDsn = config.sentry.staging.dsn
  }

  if (window.location.hostname.match('client.dev.ashwellness.io')) {
    sentryDsn = config.sentry.development.dsn
  }

  if (window.location.hostname === 'localhost') {
    sentryDsn = config.sentry.local.dsn
  }

  return { sentryDsn, baseURL }
}
